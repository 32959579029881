export default class Promo{

    constructor(data = {}) {
        this.data = data;
    }

    get name() {
        return this.data?.name || null;
    }

    get description() {
        return this.data?.description || null;
    }

}