let localState = {

    loggedIn: false,

    id: null,
    phone: null,
    name: null,
    sms_code: null,
};


export default {

    state: {...localState},

    getters: {

        user: state => name => state?.[name] || null,

        isLoggedIn: state => state.loggedIn,

        name: state => state.name,

        clientPhone: state => state.phone ? state.phone.replace(/\D/g, '') : null,

        phone_confirmed: state => state.phone_confirmed_at !== null,

    },

    mutations: {

        user(state, data) {
            Object.keys(data).forEach(key => state[key] = data[key]);
        },

        login(state) {
            state.loggedIn = true;
        },

        logout(state) {
            state.loggedIn = false
        },

        clearClient(state) {
            let local = {...localState};
            Object.assign(state, local);
        },

    }
}
