<template>

  <v-app>
    <router-view/>
  </v-app>

</template>

<script>

export default {
  name: 'App',

  components: {},

  data: () => ({
    //
  }),

  beforeCreate() {
    this.$store.dispatch('configure');
  },
};
</script>
<style>
    .theme--light.v-application {
        background: #F2F4F5 !important;
    }
</style>
