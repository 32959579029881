import Config from "@/api/DTO/config";
import http from "./http";
import User from "@/api/DTO/user";
import Payment from "@/api/DTO/payment";
import Booking from "@/api/DTO/booking";
import Device from "@/api/DTO/device";
import Point from "@/api/DTO/point";
import dayjs from "dayjs";
import Promo from "@/api/DTO/promo";

const api = {
    async config() {
        try{
            let { data, headers } = await http.get('/api/config');
            let config = new Config(data, headers);

            http.defaults.headers.common['X-CSRF-TOKEN'] = config.token;

            return config
        }catch (e) {
            return null
        }
    },

    async login(phone) {
        let {data} = await http.post('/api/login', {phone})

        return new User(data);

    },

    async code() {
        try {
            await http.get('/api/otp/code')

            return true;
        }catch (e) {
            return false;
        }

    },

    async confirm(code) {
        let {data} = await http.post('/api/otp/confirm', {code})

        return new User(data);
    },

    async resend(phone) {
        try {
            await http.post('/api/otp/resend', {phone})

            return true;
        }catch (e) {
            return false;
        }

    },

    async bookings(date = null) {
        let { data } = await http.get('/api/booking', {params: {date}})

        let result = [];

        for(let booking of data.data) {
            result.push(new Booking(booking));
        }
        return result;
    },

    async booking(id = null) {
        let { data } = await http.get('/api/booking/' + id)

        return new Booking(data.data);
    },

    async unbook(id = null) {
        await http.delete('/api/booking/unbook', {params: {id}})

        return true;
    },

    async book(body) {
        try {
            let { data } = await http.post('/api/booking/simple', body)

            return new Booking(data);
        }catch (e) {
            return false;
        }

    },

    async bookingStart(id) {
        try {
            let { data } = await http.patch(`/api/booking/${id}/start`, {
                date: dayjs().format('YYYY-MM-DD HH:mm')
            })

            return new Booking(data);
        }catch (e) {
            return false;
        }

    },

    async bookingStop(id) {
        try {
            let { data } = await http.patch(`/api/booking/${id}/stop`, {
                date: dayjs().format('YYYY-MM-DD HH:mm')
            })

            return new Booking(data);
        }catch (e) {
            return false;
        }

    },

    async cancel(id) {
        try {
            await http.delete('/api/booking/' + id)

            return true;
        }catch (e) {
            return false;
        }

    },

    async payment(body) {
        try {
            let { data } = await http.post('/api/payments/form', body)

            return new Payment(data);
        }catch (e) {
            return false;
        }

    },

    async devices(status = null) {
        try {
            let { data } = await http.get('/api/devices?status=' + ( status || '' ) )

            let result = [];

            for(let device of data.data) {
                result.push(new Device(device));
            }
            return result;
        }catch (e) {
            return false;
        }

    },

    async device(id) {
        let { data } = await http.get('/api/devices/' +id )

        return new Device(data.data)
    },

    async updateDevice(id, data) {
        await http.patch('/api/devices/' + id, data )

        return true;
    },

    async points() {
        try {
            let { data } = await http.get('/api/points')

            return new Point(data);
        }catch (e) {
            return false;
        }

    },

    async point(id) {
        let { data } = await http.get('/api/points/' +id )

        return new Point(data.data)
    },

    async updatePoint(id, data) {
        await http.patch('/api/points/' + id, data )

        return true;
    },

    async user(id) {
        let {data} = await http.get('/api/users/' + id)

        return new User(data);
    },


    async updateUser(id, data) {
        await http.patch('/api/users/' + id, data)

        return true;
    },

    async payOnPlace(id = null) {
        await http.post('/api/payments/on-place/' + id)

        return true;
    },

    async promos() {
        let {data} = await http.get('/api/promo')

        let result = [];

        for(let promo of data.data) {
            result.push(new Promo(promo));
        }
        return result;
    },

}

window.api = api;

export default api;