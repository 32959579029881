export default class User{

    constructor(data = {}) {
        this.data = data.data;
        if (!this.data?.passport){
            this.data.passport = {
                number: null,
                date: null,
                code: null,
                issued: null,
            };
        }
        if (!this.data?.details){
            this.data.details = {};
        }
    }

    get id() {
        return this.data?.id || null;
    }

    get phone() {
        return this.data?.phone || null;
    }

    get name() {
        return this.data?.name || null;
    }

    set name(val) {
        this.data.name = val;
    }

    get phone_confirmed_at() {
        return this.data?.phone_confirmed_at || null;
    }

    get passport(){
        return this.data?.passport || {};
    }

    get passport_number(){
        return this.data?.passport?.number || null;
    }

    set passport_number(val){
        this.data.passport.number = val;
    }

    get passport_birth(){
        return this.data?.passport?.birth || null;
    }

    set passport_birth(val){
        this.data.passport.birth = val;
    }

    get passport_date(){
        return this.data?.passport?.date || null;
    }

    set passport_date(val){
        this.data.passport.date = val;
    }

    get passport_code(){
        return this.data?.passport?.code || null;
    }

    set passport_code(val){
        this.data.passport.code = val;
    }

    get passport_issued(){
        return this.data?.passport?.issued || null;
    }

    set passport_issued(val){
        this.data.passport.issued = val;
    }

    get instagram(){
        return this.data?.details?.instagram || null;
    }

    set instagram(val){
        this.data.details.instagram = '@' + val.replace('@', '');
    }

    get find_us(){
        return this.data?.details?.find_us || null;
    }

    set find_us(val){
        this.data.details.find_us = val;
    }

    toArray(){
        return {
            id: this.data?.id,
            phone: this.data?.phone,
            name: this.data?.name,
            phone_confirmed_at: this.data?.phone_confirmed_at,
            passport: this.data?.passport,
            details: this.data?.details,
        }
    }

}