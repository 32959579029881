import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';

//Layouts
const LoginLayout  = () => import(/*webpackChunkName: "login"*/ '../layouts/login');
const AppLayout  = () => import(/*webpackChunkName: "login"*/ '../layouts/app');
const PageLayout  = () => import(/*webpackChunkName: "login"*/ '../layouts/page');

//Pages
const LoginPage  = () => import(/*webpackChunkName: "login"*/ '../pages/login');
const LoginOtpPage  = () => import(/*webpackChunkName: "login"*/ '../pages/login/otp');

// const PointsPage = () => import(/*webpackChunkName: "points"*/ '../pages/points');
const PointsAddPage = () => import(/*webpackChunkName: "points"*/ '../pages/points/add');
const PointsEditPage = () => import(/*webpackChunkName: "points"*/ '../pages/points/edit');
// const PointsCardPage = () => import(/*webpackChunkName: "points"*/ '../pages/points/card');
const PointsSettingsPage = () => import(/*webpackChunkName: "points"*/ '../pages/points/settings');


const SchedulePage = () => import(/*webpackChunkName: "points"*/ '../pages/schedule');

const BookingPage = () => import(/*webpackChunkName: "points"*/ '../pages/booking');
const BookingCardPage = () => import(/*webpackChunkName: "points"*/ '../pages/booking/card');
const BookingCreatePage = () => import(/*webpackChunkName: "points"*/ '../pages/booking/create');

const StaffPage = () => import(/*webpackChunkName: "staff"*/ '../pages/staff');
const StaffCardPage = () => import(/*webpackChunkName: "staff"*/ '../pages/staff/card');
const StaffAddPage = () => import(/*webpackChunkName: "staff"*/ '../pages/staff/add');
const StaffEditPage = () => import(/*webpackChunkName: "staff"*/ '../pages/staff/edit');
const StaffMovePage = () => import(/*webpackChunkName: "staff"*/ '../pages/staff/move');

const TransportPage = () => import(/*webpackChunkName: "staff"*/ '../pages/transport');
const TransportCardPage = () => import(/*webpackChunkName: "staff"*/ '../pages/transport/card');
const TransportAddPage = () => import(/*webpackChunkName: "staff"*/ '../pages/transport/add');
const TransportEditPage = () => import(/*webpackChunkName: "staff"*/ '../pages/transport/edit');
const TransportMovePage = () => import(/*webpackChunkName: "staff"*/ '../pages/transport/move');

const BatteryPage = () => import(/*webpackChunkName: "staff"*/ '../pages/battery');
const BatteryCardPage = () => import(/*webpackChunkName: "staff"*/ '../pages/battery/card');
const BatteryAddPage = () => import(/*webpackChunkName: "staff"*/ '../pages/battery/add');
const BatteryEditPage = () => import(/*webpackChunkName: "staff"*/ '../pages/battery/edit');
const BatteryMovePage = () => import(/*webpackChunkName: "staff"*/ '../pages/battery/move');

const EquipmentPage = () => import(/*webpackChunkName: "staff"*/ '../pages/equipment');
const EquipmentCardPage = () => import(/*webpackChunkName: "staff"*/ '../pages/equipment/card');
const EquipmentAddPage = () => import(/*webpackChunkName: "staff"*/ '../pages/equipment/add');
const EquipmentEditPage = () => import(/*webpackChunkName: "staff"*/ '../pages/equipment/edit');
const EquipmentMovePage = () => import(/*webpackChunkName: "staff"*/ '../pages/equipment/move');

const AppsPage = () => import(/*webpackChunkName: "apps"*/ '../pages/apps');

const ReportsPage = () => import(/*webpackChunkName: "reports"*/ '../pages/reports');

const SettingsPage = () => import(/*webpackChunkName: "settings"*/ '../pages/settings');

const UserEditPage = () => import(/*webpackChunkName: "settings"*/ '../pages/users/edit');

Vue.use(Router);

export default new Router({
    mode: 'history',

    routes: [
        {
            path: '/', component: AppLayout,
            beforeEnter: (to, from, next) => {
                if (!store.getters.isLoggedIn) {
                    next({name: 'login', replace: true});
                } else {
                    next();
                }
            },
            children: [
                // {
                //     path: '', name: 'home', component: PointsPage,
                //     meta: {
                //         title: 'Точки проката',
                //         menu: [
                //             { title: 'Добавить точку проката', to: 'points-add', icon: 'plus' },
                //         ]
                //     }
                // },

                {
                    path: '', name: 'home', component: SchedulePage,
                    meta: {
                        title: 'Яхта Романтика',
                        menu: [
                            // { title: 'Журнал обслуживания и ремонта', to: 'points-edit', icon: 'note-edit' },
                            // { title: 'Журнал инцидентов', to: 'points-edit', icon: 'badge-account-alert' },
                            // { title: 'Остановить точку', to: 'points-edit', icon: 'close-circle' },
                        ],
                    }
                },

                {
                    path: 'points', component: PageLayout,
                    children: [
                        // {
                        //     path: 'card', name: 'points-card', component: PointsCardPage,
                        //     meta: {
                        //         title: 'Яхта Романтика',
                        //         menu: [
                        //             { title: 'Журнал обслуживания и ремонта', to: 'points-edit', icon: 'note-edit' },
                        //             { title: 'Журнал инцидентов', to: 'points-edit', icon: 'badge-account-alert' },
                        //             { title: 'Остановить точку', to: 'points-edit', icon: 'close-circle' },
                        //         ],
                        //     }
                        // },
                        {
                            path: 'add', name: 'points-add', component: PointsAddPage,
                            meta: {
                                title: 'Добавление точки проката',
                            }
                        },
                        {
                            path: 'edit', name: 'points-edit', component: PointsEditPage,
                            meta: {
                                title: 'Яхта Романтика',
                            }
                        },
                        {
                            path: 'settings', name: 'points-settings', component: PointsSettingsPage,
                            meta: {
                                title: 'Яхта Романтика',
                            }
                        },
                    ]
                },

                // {
                //     path: 'schedule', component: PageLayout,
                //     children: [
                //         {
                //             path: '', name: 'schedule', component: SchedulePage,
                //             meta: {
                //                 title: 'Яхта Романтика',
                //                 menu: [
                //                     { title: 'Забронировать', to: 'booking-create', icon: 'calendar-clock' },
                //                 ]
                //             }
                //         },
                //     ],
                // },

                {
                    path: 'booking', component: PageLayout,
                    children: [
                        {
                            path: 'create', name: 'booking-create', component: BookingCreatePage,
                            meta: {
                                title: 'Яхта Романтика',
                            }
                        },
                        {
                            path: ':date(\\d{4}-\\d{2}-\\d{2})', name: 'booking', component: BookingPage,
                            meta: {
                                title: 'Яхта Романтика',
                            }
                        },
                        {
                            path: ':id', name: 'booking-card', component: BookingCardPage,
                            meta: {
                                title: 'Яхта Романтика',
                            }
                        },
                    ],
                },

                {
                    path: 'staff', component: PageLayout,
                    children: [
                        {
                            path: '', name: 'staff', component: StaffPage,
                            meta: {
                                title: 'Яхта Романтика',
                                // menu: [
                                //     { title: 'Добавить сотрудника', to: 'staff-add', icon: 'account-plus' },
                                // ]
                            }
                        },
                        {
                            path: 'card', name: 'staff-card', component: StaffCardPage,
                            meta: {
                                title: 'Яхта Романтика',
                                // menu: [
                                //     { title: 'Изменить сотрудника', to: 'staff-edit', icon: 'account-edit' },
                                //     { title: 'Переместить сотрудника', to: 'staff-move', icon: 'account-arrow-right' },
                                //     { title: 'Удалить сотрудника', to: 'staff-add', icon: 'account-remove' },
                                // ]
                            }
                        },
                        {
                            path: 'add', name: 'staff-add', component: StaffAddPage,
                            meta: {
                                title: 'Добавление сотрудника',
                            }
                        },
                        {
                            path: 'edit', name: 'staff-edit', component: StaffEditPage,
                            meta: {
                                title: 'Лионель Андрес Куччиттини Месси',
                            }
                        },
                        {
                            path: 'move', name: 'staff-move', component: StaffMovePage,
                            meta: {
                                title: 'Лионель Андрес Куччиттини Месси',
                            }
                        },
                    ],
                },

                {
                    path: 'transport', component: PageLayout,
                    children: [
                        {
                            path: '', name: 'transport', component: TransportPage,
                            meta: {
                                title: 'Список устройств',
                                // menu: [
                                    // { title: 'Добавить устройство', to: 'transport-add', icon: 'surfing' },
                                // ]
                            }
                        },
                        {
                            path: 'card/:id', name: 'transport-card', component: TransportCardPage,
                            meta: {
                                title: 'Устройство',
                                // menu: [
                                    // { title: 'Изменить устройство', to: 'transport-edit', icon: 'surfing' },
                                    // { title: 'Переместить устройство', to: 'transport-move', icon: 'sign-direction' },
                                // ]
                            }
                        },
                        {
                            path: 'move', name: 'transport-move', component: TransportMovePage,
                            meta: {
                                title: 'Яхта Романтика',
                            }
                        },
                        {
                            path: 'add', name: 'transport-add', component: TransportAddPage,
                            meta: {
                                title: 'Яхта Романтика',
                            }
                        },
                        {
                            path: 'edit', name: 'transport-edit', component: TransportEditPage,
                            meta: {
                                title: 'Яхта Романтика',
                            }
                        },
                    ],
                },

                {
                    path: 'battery', component: PageLayout,
                    children: [
                        {
                            path: '', name: 'battery', component: BatteryPage,
                            meta: {
                                title: 'Яхта Романтика',
                                // menu: [
                                //     { title: 'Добавить батарею', to: 'battery-add', icon: 'battery' },
                                // ]
                            }
                        },
                        {
                            path: 'card', name: 'battery-card', component: BatteryCardPage,
                            meta: {
                                title: 'Яхта Романтика',
                                // menu: [
                                //     { title: 'Изменить батарею', to: 'battery-edit', icon: 'battery' },
                                //     { title: 'Переместить батарею', to: 'battery-move', icon: 'sign-direction' },
                                // ]
                            }
                        },
                        {
                            path: 'move', name: 'battery-move', component: BatteryMovePage,
                            meta: {
                                title: 'Яхта Романтика',
                            }
                        },
                        {
                            path: 'add', name: 'battery-add', component: BatteryAddPage,
                            meta: {
                                title: 'Яхта Романтика',
                            }
                        },
                        {
                            path: 'edit', name: 'battery-edit', component: BatteryEditPage,
                            meta: {
                                title: 'Яхта Романтика',
                            }
                        },
                    ],
                },

                {
                    path: 'equipment', component: PageLayout,
                    children: [
                        {
                            path: '', name: 'equipment', component: EquipmentPage,
                            meta: {
                                title: 'Яхта Романтика',
                                // menu: [
                                //     { title: 'Добавить снаряжение', to: 'equipment-add', icon: 'hanger' },
                                // ]
                            }
                        },
                        {
                            path: 'card', name: 'equipment-card', component: EquipmentCardPage,
                            meta: {
                                title: 'Яхта Романтика',
                                // menu: [
                                //     { title: 'Изменить снаряжение', to: 'equipment-edit', icon: 'hanger' },
                                //     { title: 'Переместить снаряжение', to: 'equipment-move', icon: 'sign-direction' },
                                // ]
                            }
                        },
                        {
                            path: 'move', name: 'equipment-move', component: EquipmentMovePage,
                            meta: {
                                title: 'Яхта Романтика',
                            }
                        },
                        {
                            path: 'add', name: 'equipment-add', component: EquipmentAddPage,
                            meta: {
                                title: 'Яхта Романтика',
                            }
                        },
                        {
                            path: 'edit', name: 'equipment-edit', component: EquipmentEditPage,
                            meta: {
                                title: 'Яхта Романтика',
                            }
                        },
                    ],
                },

                {
                    path: 'apps', component: PageLayout,
                    children: [
                        {
                            path: '', name: 'apps', component: AppsPage,
                            meta: {
                                title: 'Заявки',
                            }
                        },
                    ],
                },

                {
                    path: 'reports', component: PageLayout,
                    children: [
                        {
                            path: '', name: 'reports', component: ReportsPage,
                            meta: {
                                title: 'Отчеты',
                            }
                        },
                    ],
                },

                {
                    path: 'settings', component: PageLayout,
                    children: [
                        {
                            path: '', name: 'settings', component: SettingsPage,
                            meta: {
                                title: 'Настройки',
                            }
                        },
                    ],
                },

                {
                    path: 'users', component: PageLayout,
                    children: [
                        {
                            path: ':id', name: 'user-edit', component: UserEditPage,
                            meta: {
                                title: 'Редактирование клиента',
                            }
                        },
                    ],
                },
            ],
        },

        {
            path: '/login', component: LoginLayout,
            beforeEnter: (to, from, next) => {
                if (store.getters.isLoggedIn) {
                    next({name: 'home', replace: true});
                } else {
                    next();
                }
            },
            children: [
                {
                    path: '', name: 'login', component: LoginPage
                },
                {
                    path: 'otp', name: 'otp', component: LoginOtpPage
                },
            ],
        },
    ],

    scrollBehavior(to, from, position) {
        if(position) {
            return position;
        }else if(to.hash){
            return {
                selector: to.hash,
                behavior: 'smooth',
            };
        }else{
            return {x: 0, y: 0};
        }
    }
})