export default class Device{

    constructor(data = {}) {
        this.data = data;
    }

    get id() {
        return this.data?.id || null;
    }

    get company_id() {
        return this.data?.company_id || null;
    }

    get point_id() {
        return this.data?.point_id || null;
    }

    get tariff_id() {
        return this.data?.tariff_id || null;
    }

    get tariff() {
        return this.data?.tariff || {};
    }

    get name() {
        return this.data?.name || null;
    }

    get img() {
        return this.data?.img || null;
    }

    get type() {
        return this.data?.type || null;
    }

    get dimensions() {
        return this.data?.dimensions || null;
    }

    get weight() {
        return this.data?.weight || null;
    }

    get speed() {
        return this.data?.speed || null;
    }

    get max_weight() {
        return this.data?.max_weight || null;
    }

    get level() {
        return this.data?.level || null;
    }

    get inventory_number() {
        return this.data?.inventory_number || null;
    }

    get serial() {
        return this.data?.serial || null;
    }

    get status() {
        return this.data?.status || null;
    }

    set status(value) {
        this.data.status = value;
    }

    get bookings() {
        return this.data?.bookings || [];
    }

    get price() {
        return this.data?.price || 0;
    }

    get duration() {
        return this.data?.duration || this.data?.tariff?.duration || 20;
    }


}