import axios from 'axios'
import {API_URL} from "@/misc/constants";

const http = axios.create({
    baseURL: process.env.ENV_NAME === 'production' ? '/' : API_URL,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
    },
    defaults: {
        withCredentials: true
    }
});

export default http;
