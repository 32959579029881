export default class Payment{

    constructor(data = {}) {
        this.data = data.data;
        this.message = data.message;
    }

    get bank_url() {
        return this.data.form_url || null;
    }

    get error() {
        return this.message || null;
    }

}