const localState = {
    points: [
        // {
        //     type: 'route', 'link' or 'func',
        //     route: {name: '', params: []},
        //     link: text,
        //     func: {name: '', params: [], confirm: '', alert: ''},
        //     text: text,
        //     icon: text,
        // }
    ],
    action: null,
    confirm: null,
    alert: null
}

export default {

    state: {...localState},

    getters: {
        menuPoints: state => state.points,
    },

    mutations: {
        setMenuPoints(state, data) {
            state.points = data;
        },

        addMenuPoint(state, data) {
            state.points.push(data)
        },

        setMenuAction(state, action) {
            state.action = action;
        },

        setMenuConfirm(state, confirm) {
            state.confirm = confirm;
        },

        setAlert(state, alert) {
            state.alert = alert;
        },

        resetMenu(state) {
            Object.assign(state, localState);
        }
    },

}
