import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import config from './modules/config'
import menu from './modules/menu'
import storage from "./plugins/storage"
import redirector from "./plugins/redirector"
import {mergeDeep} from "@/misc/functions";

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {user, config, menu},

    plugins: [storage, redirector],

    mutations: {

        __initStore(state) {
            let version = localStorage.getItem('version_operator');
            if(version === process.env.VERSION && localStorage.getItem('state_operator')) {
                let tmp = mergeDeep(state, JSON.parse(localStorage.getItem('state_operator')));
                this.replaceState(tmp);
            }else{
                localStorage.removeItem('state_operator');
                localStorage.setItem('version_operator', process.env.VERSION)
            }
        },

        __toInit(state, data){
            this.replaceState(data);
        }

    },

});

store.commit('__initStore', store.state);

window.$store = store;

export default store
