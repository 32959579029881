export default class Point{

    constructor(data = {}) {
        this.data = data;
        if (!this.data?.details){
            this.data.details = {};
        }
    }

    get id() {
        return this.data?.id || null;
    }

    get name() {
        return this.data?.name || null;
    }

    get flow(){
        return this.data?.details?.flow || 'default';
    }

    set flow(val){
        this.data.details.flow = val;
    }

}