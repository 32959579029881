import dayjs from 'dayjs';

export default class Booking{

    constructor(data = {}) {
        this.data = data;
    }

    get id() {
        return this.data?.id || null;
    }

    get starts_at() {
        return dayjs(this.data?.starts_at);
    }

    get ends_at() {
        return dayjs(this.data?.ends_at);
    }

    get date() {
        return this.data?.starts_at || '';
    }

    get starts_at_date() {
        return this.data?.starts_at.substring(0, 10);
    }

    get starts_at_time() {
        return this.data?.starts_at.substring(11, 16);
    }

    get ends_at_time() {
        return this.data?.ends_at.substring(11, 16);
    }

    get client_id() {
        return this.data?.user?.id || null;
    }

    get client_name() {
        return this.data?.user?.name || 'Новый клиент';
    }

    get client_phone() {
        return this.data?.user?.phone || 'Не указано';
    }

    get instructor_name() {
        return this.data?.instructor?.name || 'Не закреплен';
    }

    get device_name() {
        return this.data?.device?.name || 'Не указано';
    }

    get device_serial() {
        return this.data?.device?.serial || 'Не указано';
    }

    get has_payments() {
        return !!this.data?.payments_count;
    }

    get has_payments_on_place() {
        return !!this.data?.payments_on_place;
    }

}