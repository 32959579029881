import router from "../../router";

export default store => {
    // eslint-disable-next-line no-unused-vars
    store.subscribe((mutation, state) => {
        switch (mutation.type) {

            case 'login':
                if (mutation.payload) {
                    if (router.currentRoute.name !== mutation.payload) {
                        router.push({name: mutation.payload});
                    }
                } else if (['login', 'otp'].includes(router.currentRoute.name)) {
                    router.push({name: 'home'})
                }
                break;

            case 'logout':
                if (!['login', mutation.payload].includes(router.currentRoute.name)) {
                    router.push({name: mutation.payload || 'login'});
                }
                break;

            case 'redirect':
                router.push({name: mutation.payload});
                break;
        }
    });
}
